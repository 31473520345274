import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Blog } from '../../model/Blog';
import { BlogService } from '../../shared/service/serviceblog/blog.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-blog-pregunta',
  templateUrl: './blog-pregunta.component.html',
  styleUrls: ['./blog-pregunta.component.css'],
})
export class BlogPreguntaComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @Output()
  obtenerPreguntas = new EventEmitter<any>();
  blog: Blog = new Blog();

  constructor(private blogService: BlogService) {}

  ngOnInit(): void {
    this.resetForm();
  }

  registrarPrgunta(): void {
    if (this.validarDatos()) {
      Swal.fire({
        icon: 'error',
        title: 'Algo salió mal',
        text: 'Hay campos sin diligenciar',
        confirmButtonColor: '#3f66ac',
        showConfirmButton: true,
        confirmButtonText: `Ok`,
      });
    } else {
      this.blogService.registrarPregunta(this.blog).subscribe(
        (response: any) => {
          this.closebutton.nativeElement.click();
          this.resetForm();
          this.obtenerPreguntas.emit();
        },
        (err) => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Algo salió mal',
            text: err.error.result.error_msg,
            confirmButtonColor: '#3f66ac',
            showConfirmButton: true,
            confirmButtonText: `Ok`,
          });
        }
      );
    }
  }

  public validarDatos(): boolean {
    let existe: boolean;
    for (const key in this.blog) {
      console.log(this.blog[key]);
      if (this.blog[key] === null || this.blog[key] === '') {
        existe = true;
      }
    }
    return existe;
  }

  resetForm(): void {
    this.blog.nombre = null;
    this.blog.apellido = null;
    this.blog.correo = null;
    this.blog.telefono = null;
    this.blog.pregunta = null;
    this.blog.isenvioCorreo = true;
  }
}
