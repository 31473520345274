<table class="table table-sm caption-top">
  <caption>Lista de preguntas</caption>
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Pregunta</th>
      <th scope="col">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let blog of listaBlog">
      <th scope="row">{{blog.Id}}</th>
      <td>{{blog.pregunta}}</td>
      <td>
        <div *ngIf="blog.respuesta==null">
         <app-admin-respuesta-blog [blog]="blog" (propagarByEvent)="recargarPreguntas()"></app-admin-respuesta-blog>
        </div>
        <div *ngIf="blog.respuesta!==null">
          <app-blog-detail [blog]="blog"></app-blog-detail>
          <app-admin-respuesta-blog [blog]="blog" (propagarByEvent)="recargarPreguntas()"></app-admin-respuesta-blog>
         </div>
      </td>
    </tr>
  </tbody>
</table>
