import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpaciertoService } from '../httpacierto/httpacierto.service';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  private API: string;

  constructor(private http: HttpaciertoService) {
    this.API = environment.apiPublicUrl;
  }
  obtenerPreguntas(): Observable<any> {
    return this.http.get(this.API + '/ControllerBlog?page=1');
  }
  buscarPregunta(pregunta: string): Observable<any> {
    return this.http.get(this.API + '/ControllerBlog?page=1');
  }

  registrarPregunta(data: any) {
    console.log(data);
    return this.http.post(this.API + '/ControllerBlog', data);
  }

  responderPregunta(data: any) {
    console.log(data);
    return this.http.put(this.API + '/ControllerBlog', data);
  }
}
