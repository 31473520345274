<button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModalFullscreen"
  [attr.data-bs-target]="'#ModalPregunta' + blog.Id">
  Ver respuesta
</button>
<div class="modal fade" id="ModalPregunta{{blog.Id}}" tabindex="-1" aria-labelledby="exampleModalFullscreenLabel"
  aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        ​<picture>
          <img src="../../assets/img/Logo_Banner.svg" class="img-fluid" alt="Logo Acierto.">
        </picture>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="card card-respuesta-blog">
          <div class="card-body">
            <h5 class="card-title"><strong>{{pregunta}}</strong></h5>
            <h6 class="card-subtitle mb-2 text-muted">
              <span class="badge bg-light text-dark">Autor: </span>
              {{nombre}} {{apellido}}
            </h6>
            <h6 class="card-subtitle mb-2 text-muted">
              <span class="badge bg-light text-dark">Fecha creación: </span>
              <span class="badge bg-secondary">{{fecha_pregunta | date:'short'}}</span>
              <span class="badge bg-light text-dark">Fecha respuesta: </span>
              <span class="badge bg-secondary">{{fecha_respuesta | date:'short'}}</span>

            </h6>
            <p class="card-text respuesta-blog-contenido">{{respuesta}}
            </p>
          </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
