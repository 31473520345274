import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  //token
  setToken(token: string) {
    localStorage.setItem('session', token);
  }

  getToken() {
    return localStorage.getItem('session');
  }

  deleteToken() {
    localStorage.removeItem('session');
  }

  isLoggedIn() {
    const usertoken = this.getToken();
    if (usertoken != null) {
      return true
    }else{
      return false;
    }
  }
}
