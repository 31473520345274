import { NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, HashLocationStrategy} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { PublicRoutingModule } from './public-routing.module';
import { HomeComponent } from './component/home/home.component';
import { BlogComponent } from './component/blog/blog.component';
import { NavComponent } from './component/nav/nav.component';
import { FooterComponent } from './component/footer/footer.component';
import { HeaderComponent } from './component/header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { PublicComponent } from './public.component';
import { LoginComponent } from './component/login/login.component';
import { BlogDetailComponent } from './component/blog-detail/blog-detail.component';
import { BlogPreguntaComponent } from './component/blog-pregunta/blog-pregunta.component';
import { BlogListComponent } from './component/blog-list/blog-list.component';
import { FilterPipe } from './shared/filter.pipe';
import { AdminComponent } from './component/admin/admin.component';
import { AdminBlogListComponent } from './component/admin-blog-list/admin-blog-list.component';
import { AdminRespuestaBlogComponent } from './component/admin-respuesta-blog/admin-respuesta-blog.component';
import { LoginGuard } from './shared/auth/login.guard';


@NgModule({
  declarations: [
    HomeComponent,
    BlogComponent,
    NavComponent,
    FooterComponent,
    HeaderComponent,
    PublicComponent,
    LoginComponent,
    BlogDetailComponent,
    BlogPreguntaComponent,
    BlogListComponent,
    FilterPipe,
    AdminComponent,
    AdminBlogListComponent,
    AdminRespuestaBlogComponent,
  ],
  exports: [PublicComponent],
  imports: [
    CommonModule,
    PublicRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [ LoginGuard,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
})
export class PublicModule {}
