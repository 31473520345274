<nav class="navbar navbar-expand-lg  navbar-light bg-light navbar-fixed-top smaller">
  <div class="container-fluid ">

    <a class="navbar-brand" href="#">
      <img src="../../assets/img/Logo_Banner.svg" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link nav-item-bold text-uppercase" [routerLink]="['/inicio']"
            [routerLinkActive]="['is-active']">Inicio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-item-bold text-uppercase" [routerLink]="['/preguntas']"
            [routerLinkActive]="['is-active']">Preguntar</a>
        </li>
      </ul>

      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li>
          <h6 class="text-uppercase fw-bold mb-4">
            <a class="nav-link nav-item-bold text-uppercase" [routerLink]="['/admin']"
              [routerLinkActive]="['is-active']"><i class="fas fa-sign-in-alt"></i></a>
          </h6>
        </li>
      </ul>

    </div>
  </div>
</nav>
