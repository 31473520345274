<div class="container">
  <br />
  <div class="row">
    <div class="col-12">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li>
          <h6 class="text-uppercase fw-bold mb-4">
            <a class="nav-link nav-item-bold text-uppercase" (click)="unloging()"><i class="fas fa-sign-out-alt"></i></a>
          </h6>
        </li>
      </ul>
    </div>

    <div class="col-12">
      <app-admin-blog-list ></app-admin-blog-list>
    </div>
  </div>
  <br>
</div>

