import { Component, Input, OnInit } from '@angular/core';
import { Blog } from '../../model/Blog';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css'],
})
export class BlogDetailComponent implements OnInit {
  @Input() blog: Blog;
  nombre: string;
  apellido: string;
  pregunta: string;
  respuesta: string;
  fecha_pregunta: string;
  fecha_respuesta: string;
  constructor() {}

  ngOnInit(): void {
    this.nombre = this.blog.nombre;
    this.apellido = this.blog.apellido;
    this.pregunta = this.blog.pregunta;
    this.respuesta = this.blog.respuesta;
    this.fecha_pregunta = this.blog.fecha_pregunta;
    this.fecha_respuesta = this.blog.fecha_respuesta;
  }
}
