<div class="section-login container h-100">
  <div class="d-flex justify-content-center h-100">
    <div class="user_card">
      <div class="d-flex justify-content-center">
        <div class="brand_logo_container">
          <img src="../../assets/img/logo.svg" class="brand_logo" alt="Logo">
        </div>
      </div>
      <div class="d-flex justify-content-center form_container">
        <form [formGroup]="contacto" class="needs-validation" novalidate (ngSubmit)="onSubmit()">
          <div class="input-group mb-3">
            <span class="input-group-text" id="addon-wrapping">
              <i class="fas fa-user "></i>
            </span>
            <input type="text" class="form-control" name="usuario" placeholder="usuario" aria-label="usuario"
              aria-describedby="addon-wrapping" required
              formControlName="usuario"  [ngClass]="{ 'is-invalid':  f.usuario.errors }">
            <div *ngIf="f.usuario.touched && f.usuario.invalid" class="invalid-feedback">
              <div *ngIf="f.usuario.errors.required">El usuario es obligatorio.</div>
              <div *ngIf="f.usuario.errors.minlength">El usuario debe tener 7 caracteres..</div>
              <div *ngIf="f.usuario.errors.email">Por favor,El usuario debe ser una dirección de correo electrónico válida.</div>
            </div>
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="addon-wrapping">
              <i class="fas fa-key"></i>
            </span>
            <input type="password" class="form-control" name="password" placeholder="contraseña"
              aria-label="password" aria-describedby="addon-wrapping" required
              formControlName="password"  [ngClass]="{ 'is-invalid':  f.password.errors }">
              <div *ngIf="f.password.touched && f.password.invalid" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">El password es obligatorio</div>
                <div *ngIf="f.password.errors.minlength">Por favor, El password debe tener mas de 7 caracteres..</div>
              </div>
          </div>
          <div class="d-flex justify-content-center mt-3 login_container">
            <button type="submit" name="button" class="btn login_btn" [disabled]="!contacto.valid">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
