import { Component, Input, Output, OnInit, EventEmitter, ViewChild} from '@angular/core';
import { Blog } from '../../model/Blog';
import Swal from 'sweetalert2';

import { BlogService } from '../../shared/service/serviceblog/blog.service';
import { RespuestaBlog } from '../../model/respuestablog';
import { TokenService } from '../../shared/service/token/token.service';

@Component({
  selector: 'app-admin-respuesta-blog',
  templateUrl: './admin-respuesta-blog.component.html',
  styleUrls: ['./admin-respuesta-blog.component.css']
})
export class AdminRespuestaBlogComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @Output()
  propagarByEvent = new EventEmitter<any>();
  @Input()
  blog: Blog;


  respuestaForm: RespuestaBlog =  new RespuestaBlog();

  constructor(private blogService: BlogService, private serviceToken: TokenService) {}

  ngOnInit(): void {
    this.resetForm()
    this.respuestaForm.respuesta = this.blog.respuesta;
  }

  registrarRespuesta(id: number): void {
    this.respuestaForm.id = id;
    this.respuestaForm.token = this.serviceToken.getToken();
    if (this.validarDatos()) {
      Swal.fire({
        icon: 'error',
        title: 'Algo salió mal',
        text: 'Hay campos sin diligenciar',
        confirmButtonColor: '#3f66ac',
        showConfirmButton: true,
        confirmButtonText: `Ok`,
      });
    } else {
      this.blogService.responderPregunta(this.respuestaForm).subscribe(
        (response: any) => {
          this.closebutton.nativeElement.click();
          this.propagarByEvent.emit();
          this.resetForm();


        },
        (err) => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Algo salió mal',
            text: err.error.message,
            confirmButtonColor: '#3f66ac',
            showConfirmButton: true,
            confirmButtonText: `Ok`,
          });
        }
      );
    }
  }

  public validarDatos(): boolean {
    let existe: boolean;
    for (const key in this.respuestaForm) {
      if (this.respuestaForm[key] === null || this.respuestaForm[key] === '') {
        existe = true;
      }
    }
    return existe;
  }

  resetForm(): void {
    this.respuestaForm.id = 0;
    this.respuestaForm.respuesta =  '';
    this.respuestaForm.token = '0';
  }
}
