import { Component, Input, OnInit } from '@angular/core';
import { Blog } from '../../model/Blog';
import { BlogService } from '../../shared/service/serviceblog/blog.service';

@Component({
  selector: 'app-admin-blog-list',
  templateUrl: './admin-blog-list.component.html',
  styleUrls: ['./admin-blog-list.component.css']
})
export class AdminBlogListComponent implements OnInit {
  listaBlog: Blog[] = [];

  @Input()
  public buscarPorPregunta: string = '';
  constructor(private blogService: BlogService) {}

  ngOnInit(): void {
    this.obtenerPreguntas();
  }

  public recargarPreguntas(): void {
    this.obtenerPreguntas();
  }

  obtenerPreguntas(): void {
    this.blogService.obtenerPreguntas().subscribe((data: any) => {
      this.listaBlog = data;
    });
  }
}

