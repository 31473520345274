import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { first } from 'rxjs/operators';

import { LoginService } from '../../shared/service/login/login.service';
import { TokenService } from '../../shared/service/token/token.service';
import { IToken } from '../../model/token';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  token: IToken = {
    token:''
  }
  constructor(private serviceToken: TokenService, private loginService:LoginService,private router:Router) { }

  ngOnInit(): void {
    this.validarAutorizacion();
  }

  unloging(){
    this.serviceToken.deleteToken();
    this.redirect();
  }

  validarAutorizacion(): void {
    this.token.token = this.serviceToken.getToken();
    console.log(this.token.token);
    this.loginService.loginToken(this.token)
      .pipe(first())
      .subscribe(
        dataToken => {
        },(err) => {
          Swal.fire({
            icon: 'error',
            title: 'No esta autorizado',
            text: err.error.result.error_msg,
            confirmButtonColor: '#3f66ac',
            showConfirmButton: true,
            confirmButtonText: `Ok`,
          });
          this.redirect();
        }
     );
  }

  redirect(): void {
    const redirect = '/login';
    this.router.navigate([redirect]);
  }
}
