export class Blog {
  Id: number | undefined;
  nombre: string | undefined;
  apellido: string | undefined;
  correo: string | undefined;
  telefono: string | undefined;
  pregunta: string | undefined;
  fecha_pregunta: string | undefined;
  respuesta: string | undefined;
  fecha_respuesta: string | undefined;
  isenvioCorreo: boolean | undefined;
}
