<div class="container">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-8 col-lg-9">
      <h1>Acierto A&amp;S nos apoya en la gestión.</h1>
      <p>

        El propósito principal y razón de ser de Acierto A&amp;S es apoyar en la gestión empresarial de las
        micros, pequeñas y medianas empresas por medios de todos y cada uno de nuestros servicios,
        para ello, a nuestros clientes, al contratar con nosotros cualquiera de los servicios del portafolio
        ofrecido, se le garantiza además del cumplimiento de lo contratado un asesoramiento para el
        fortalecimiento de su gestión y un acompañamiento en la implementación de las acciones de
        mejora.
      </p>
      <h4>Con Acierto A&S siempre ganas porque cuentas con:</h4>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">Un equipo de personas a quienes consultar.</li>
        <li class="list-group-item">Asesoramiento especializado.</li>
        <li class="list-group-item">Soporte y respaldo para la gestión.</li>
        <li class="list-group-item">Soluciones puntuales a sus problemas</li>
        <li class="list-group-item">Servicios eficaces para el crecimiento.</li>
        <li class="list-group-item">Ahorros económicos por gestión integral</li>
      </ul>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-3">

      <div class="card col" style="margin-top: 30px;">
        <div class="row ">
          <div class="col-12">
            <div class="card-body">
              <h5 class="card-title">NOVEDADES EN EL ICA CARTAGENA AÑO 2023 </h5>
              <p class="card-text">Tenga presente que de acuerdo con el Parágrafo segundo del artículo 61 del acuerdo 107 del 14 de diciembre de 2022 de Cartagena de Indias, que indica lo siguiente...</p>
              <a href="../../assets/document/pdf/NOVEDADES_ICA_CARTAGENA_2023.pdf" target="_blank" class="btn btn-secondary">Ver
                mas</a>
            </div>
          </div>
        </div>
      </div>

  </div>
</div>
