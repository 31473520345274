import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPregunta',
})
export class FilterPipe implements PipeTransform {
  transform(value: any, arg: any): any {
    const resultPregunta = [];
    for (const blog of value) {
      if (blog.pregunta.toLowerCase().indexOf(arg) > -1) {
        resultPregunta.push(blog);
      }
    }
    return resultPregunta;
  }
}
