<table class="table table-sm">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Pregunta</th>
      <th scope="col">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let blog of listaBlog | filterPregunta:buscarPorPregunta">
      <th scope="row">{{blog.Id}}</th>
      <td>{{blog.pregunta}}</td>
      <td>
        <div *ngIf="blog.respuesta!==null">
          <app-blog-detail [blog]="blog"></app-blog-detail>
        </div>
      </td>
    </tr>
  </tbody>
</table>
