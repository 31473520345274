import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpaciertoService } from '../httpacierto/httpacierto.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private API: string;

  constructor(private http: HttpaciertoService) {
    this.API = environment.apiPublicUrl;
  }

  login(data: any) {
    return this.http.post(this.API + '/auth', data);
  }
  loginToken(data: any) {
    return this.http.post(this.API + '/auth?token=aut', data);
  }
  unlogin(data: any) {
    return this.http.post(this.API + '/auth', data);
  }
}
