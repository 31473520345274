<!-- Footer -->
<footer class="text-center text-lg-start bg-light text-muted">
  <!-- Section: Links  -->
  <section class="  p-1 border-bottom">
    <div class="container text-center text-md-start mt-5">
      <!-- Grid row -->
      <div class="row mt-3">
        <!-- Grid column -->
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <!-- Content -->
          <h6 class="text-uppercase fw-bold mb-4">
            <i class="fas fa-gem me-3"></i>Acierto A&S
          </h6>
          <p>
            <strong>“Te acompañamos en la gestión empresarial"</strong>, somos especialista, hemos impactado el
            fortalecimiento de los emprendimientos empresariales por medios de nuestros servicios que buscan asesorar
            integralmente en la gestión de nuestros clientes para su permanencia y crecimiento en el mercado.
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="text-uppercase fw-bold mb-4">
            Servicios
          </h6>
          <p>
            <a href="#!" class="text-reset">Gestión contable</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Gestión tributaria</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Gestión financiera</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Aseguramiento de la información</a>
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <!-- Links -->
          <h6 class="text-uppercase fw-bold mb-4">
            Contacto
          </h6>
          <p><i class="fas fa-home me-3"></i> Direccion </p>
          <p>
            <i class="fas fa-envelope me-3"></i>
            correo@example.com
          </p>
          <p><i class="fas fa-phone me-3"></i> + 01 234 567 88</p>
          <p><i class="fas fa-print me-3"></i> + 01 234 567 89</p>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
  </section>
  <!-- Section: Links  -->

  <!-- Copyright -->
  <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
    © 2021 Copyright:
    <a class="text-reset fw-bold" href="https://ecosoftapp.com/">Ecosoft.com</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
