<div class="container">
  <br />
  <div class="row">
    <div class="col-12">
      <app-blog-pregunta (obtenerPreguntas)="obtenerPreguntas()"></app-blog-pregunta>
    </div>
    <!-- Button trigger modal -->
    <div class="col-12">
      <label for="exampleInputEmail1" class="form-label">Buscar pregunta</label>
      <input type="text" class="form-control" name="buscarPorPregunta" placeholder="Buscar por pregunta"
        [(ngModel)]="buscarPorPregunta">
    </div>
    <div class="col-12">
      <app-blog-list [buscarPorPregunta]="buscarPorPregunta"></app-blog-list>
    </div>
  </div>
  <br>
</div>
