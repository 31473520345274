import { Component, Input, OnInit } from '@angular/core';
import { Blog } from '../../model/Blog';
import { BlogService } from '../../shared/service/serviceblog/blog.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css'],
})
export class BlogListComponent implements OnInit {
  listaBlog: Blog[] = [];

  @Input()
  public buscarPorPregunta: string = '';
  constructor(private blogService: BlogService) {}

  ngOnInit(): void {
    this.obtenerPreguntas();
  }

  obtenerPreguntas(): void {
    this.blogService.obtenerPreguntas().subscribe((data: any) => {
      this.listaBlog = data;
    });
  }
  /**
  buscarPreguntas(pregunta: string): void {
    this.blogService.buscarPregunta(pregunta).subscribe((data: any) => {
      this.listaBlog = data;
    });
  }*/
}
