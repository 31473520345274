import { Component, OnInit, ViewChild } from '@angular/core';
import { Blog } from '../../model/Blog';
import { BlogService } from '../../shared/service/serviceblog/blog.service';
import { BlogListComponent } from '../blog-list/blog-list.component';

@Component({
  selector: 'app-bloc',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
})
export class BlogComponent implements OnInit {
  @ViewChild(BlogListComponent, { static: true })
  list: BlogListComponent;

  public buscarPorPregunta: string = '';
  constructor() {}
  ngOnInit(): void {}

  public obtenerPreguntas(): void {
    this.list.obtenerPreguntas();
  }
}
