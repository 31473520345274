<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#ModalResponderPregunta"
  [attr.data-bs-target]="'#ModalResponderPregunta' + blog.Id">
  Responder
</button>
<div class="modal fade" id="ModalResponderPregunta{{blog.Id}}" tabindex="-1" aria-labelledby="ModalResponderPregunta"
  aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        ​<picture>
          <img src="../../assets/img/Logo_Banner.svg" class="img-fluid" alt="Logo Acierto.">
        </picture>
        <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="resetForm()"></button>
      </div>
      <div class="modal-body">
        <div class="card card-respuesta-blog">
          <div class="card-body">
            <h5 class="card-title"><strong>{{blog.pregunta}}</strong></h5>
            <h6 class="card-subtitle mb-2 text-muted">
              <span class="badge bg-light text-dark">Autor: </span>
              {{blog.nombre}} {{blog.apellido}}
            </h6>
            <h6 class="card-subtitle mb-2 text-muted">
              <span class="badge bg-light text-dark">Fecha creación: </span>
              <span class="badge bg-secondary">{{blog.fecha_pregunta | date:'short'}}</span>
              <span class="badge bg-light text-dark">Fecha respuesta: </span>
              <span class="badge bg-secondary">{{blog.fecha_respuesta | date:'short'}}</span>

            </h6>

              <form class="needs-validation" novalidate>
                <div class="mb-3">
                  <label for="pregunta" class="col-form-label">Responder pregunta:</label>
                  <textarea class="form-control" id="pregunta" name="pregunta"  rows="4"
                    cols="50" [(ngModel)]="respuestaForm.respuesta" value="{{blog.respuesta}}"></textarea>
                </div>
              </form>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="resetForm()">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="registrarRespuesta(blog.Id)">Responder</button>
        </div>
      </div>
    </div>
