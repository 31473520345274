import { Component, OnInit } from '@angular/core';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { Contacto } from '../../model/contacto';
import { LoginService } from '../../shared/service/login/login.service';
import { TokenService } from '../../shared/service/token/token.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  contacto: FormGroup;
  usuarioForm: Contacto ={
    usuario:'',
    password:''
  }
  submitted = false;

  constructor(private loginService:LoginService, private serviceToken: TokenService, private formBuilder: FormBuilder, private router:Router) {}

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm(){
    this.contacto = this.formBuilder.group({
      usuario: ['', [Validators.required, Validators.minLength(5), Validators.email]],
      password: ['', [Validators.required, Validators.minLength(7)]]
    });
  }

  get f() { return this.contacto.controls; }

  onSubmit(): void{
    this.submitted = true;
    if (this.contacto.invalid) {
        return;
    }else{
      this.usuarioForm.password = this.contacto.value.password;
      this.usuarioForm.usuario = this.contacto.value.usuario;
    }

    this.login(this.usuarioForm);
  }

  onReset(): void {
    this.submitted=false;
    this.contacto.reset();
  }


  login(data: Contacto): void {
    this.loginService.login(data)
      .pipe(first())
      .subscribe(
        dataToken => {
          this.admin(dataToken.toString());
        },(err) => {
          Swal.fire({
            icon: 'error',
            title: 'No se pudo llevar a cabo el login',
            text: err.error.result.error_msg,
            confirmButtonColor: '#3f66ac',
            showConfirmButton: true,
            confirmButtonText: `Ok`,
          });
        }
     );
  }

 private admin(token:string): void {
    this.serviceToken.setToken(token);
    const redirect = '/admin';
    this.onReset();
    this.router.navigate([redirect]);
  }
}
