<header>
  <app-header></app-header>
</header>
<nav>
  <app-nav></app-nav>
</nav>
<section>
  <router-outlet></router-outlet>
</section>
<footer>
  <app-footer></app-footer>
</footer>
