<!-- Button trigger modal -->
<button type="button" class="btn btn-primary registar  btn-flotante" data-bs-toggle="modal"
  data-bs-target="#registar-pregunta" data-bs-whatever="@registar pregunta">¡¡ Quieres realizar una
  pregunta!!</button>
<!-- Modal -->
<div class="modal fade" id="registar-pregunta" tabindex="-1" aria-labelledby="registarPreguntaModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="registarPreguntaModalLabel">Nueva Pregunta</h5>
        <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="resetForm()"></button>
      </div>
      <div class="modal-body">

        <form class="needs-validation" novalidate>
          <div class="mb-3">
            <div style="color: #dc3545;">
              Todos los campos son requerido.
            </div>
          </div>

          <div class="mb-3">
            <label for="nombre" class="col-form-label" required>Nombre</label>
            <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="blog.nombre"
              required="required">
            <div class="invalid-feedback">
              Please provide a valid city.
            </div>
          </div>
          <div class="mb-3">
            <label for="apellido" class="col-form-label" required>Apellidos:</label>
            <input type="text" class="form-control" id="apellido" name="apellido" [(ngModel)]="blog.apellido">
          </div>
          <div class="mb-3">
            <label for="email" class="col-form-label" required>Email:</label>
            <input type="email" class="form-control" id="correo" name="correo" [(ngModel)]="blog.correo">
          </div>
          <div class="mb-3">
            <label for="telefono" class="col-form-label">Telefono:</label>
            <input type="tel" class="form-control" id="telefono" name="telefono" [(ngModel)]="blog.telefono">
          </div>
          <div class="mb-3">
            <label for="pregunta" class="col-form-label">Pregunta:</label>
            <textarea class="form-control" id="pregunta" name="pregunta" [(ngModel)]="blog.pregunta" rows="4"
              cols="50"></textarea>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="isenvioCorreo" name="isenvioCorreo"
              [(ngModel)]="blog.isenvioCorreo " [ngModel]="true">
            <label class="form-check-label" for="isenvioCorreo">Desear recibir correo con la respuesta</label>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="resetForm()">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="registrarPrgunta()">Enviar pregunta</button>
      </div>
    </div>
  </div>
</div>
